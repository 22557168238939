import React from "react";
import { graphql, Link } from "gatsby"
import { useLocation } from '@reach/router';
import Metadata from '../components/Metadata';
import ReactMarkdown from 'react-markdown'
import rehypePrism from '@mapbox/rehype-prism';
import Image from "gatsby-plugin-sanity-image"
import rehypeRaw from 'rehype-raw';

import SanityPortableText from "../components/SanityPortableText";
import Share from '../components/Share';
import PageLayout from "../layouts/Page";
import Person from "../components/Person"
import Button from "../components/Button"
import BlogSeries from "../components/BlogSeries";
import BlogPostsListing from "../components/pancakes/BlogPostsListing";

import './Post.scss';

export const query = graphql`
  query SanityBlogPostQuery($title: String!, $tags: [String], $series: String) {
    sanityBlogPost(title: {eq: $title}) {
      title
      rawDate: date
      date(formatString: "MMMM DD, YYYY")
      slug {
        current
      }
      authors {
        name
        slug {
          current
        }
        jobtitle
        headline
        image {
          ...ImageWithPreview
        }
      }
      featuredImage {
        altText
        image {
          ...ImageWithPreview
          asset {
            url
          }
        }
      }
      hideFeaturedImage
      _rawText(resolveReferences:{maxDepth:1000})
      textMarkdown
      tags {
        slug {
          current
        }
        name
      }
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
      relatedPosts {
        title
        date(formatString: "MMMM DD, YYYY")
        slug {
          current
        }
        authors {
          name
          image {
            ...ImageWithPreview
          }
        }
        _rawText(resolveReferences:{maxDepth:1000})
        textMarkdown
        excerpt
        featuredImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
      }
    }
    automaticRelatedPosts: allSanityBlogPost(
      sort: { fields: date, order: DESC },
      filter: {tags: {elemMatch: {_id: {in: $tags}}}, title: {ne: $title}},
      limit: 2
    ) {
      nodes {
        title
        date(formatString: "MMMM DD, YYYY")
        slug {
          current
        }
        authors {
          name
          image {
            ...ImageWithPreview
          }
        }
        _rawText(resolveReferences:{maxDepth:1000})
        textMarkdown
        excerpt
        featuredImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
      }
    }
    BlogSeries: allSanityBlogPost(
      filter: {series: {name: {eq: $series}}}
      sort: {fields: date, order: ASC}
    ) {
      nodes {
        slug {
          current
        }
        title
      }
      totalCount
    }
  }
`;

const PreComponent = props => {
  const {children, className = "language-text"} = props;

  return (
    <div className="gatsby-highlight">
      <pre className={className}>
        {children}
      </pre>
    </div>
  )
}

const ImageComponent = props => {
  const {src, alt} = props

  return (
    <img loading="lazy" src={src} alt={alt ? alt : ""} className="PostImage" />
  )
}

const SanityBlog = props => {
  const location = useLocation();
  const blogPost = props.data.sanityBlogPost;
  const automaticRelatedPosts = props.data.automaticRelatedPosts.nodes;
  let finalRelatedPosts = [];
  finalRelatedPosts = blogPost.relatedPosts.length > 0 ? finalRelatedPosts.concat(blogPost.relatedPosts, automaticRelatedPosts) : automaticRelatedPosts;

  return (
    <PageLayout>
      <Metadata 
        title={blogPost.seo?.metaTitle || blogPost.title} 
        description={blogPost.seo?.metaDescription} 
        image={blogPost.seo?.ogImage?.asset.url || blogPost.featuredImage?.image.asset.url || null}
      >
        {blogPost.tags && blogPost.tags.map(tag => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
        <meta property="article:author" content="Okteto Team" />
        {blogPost.rawDate &&
          <meta property="article:published_time" content={blogPost.rawDate} />
        }
        {blogPost.rawDate &&
          <meta property="article:modified_time" content={blogPost.rawDate} />
        }
      </Metadata>
      <article className="Post" id={props.path}>
        {blogPost.date &&
          <div className="PostDate">
            Posted on {blogPost.date}
          </div>
        }
        <h1>{blogPost.title}</h1>

        {!blogPost.hideFeaturedImage && blogPost.featuredImage?.image && (
          <div className="PostCoverImage">
            <Image
              {...blogPost.featuredImage.image}
              width={952}
              sizes={`(max-width: 1000px) 100vw, 952px`}
              alt={blogPost.featuredImage.altText || ""}
            />
          </div>
        )}

        {props.pageContext.series && <BlogSeries posts={props.data.BlogSeries} seriesName={props.pageContext.series} currentPosts={blogPost.title} />}

        {blogPost._rawText && <SanityPortableText blocks={blogPost._rawText} />}
        {blogPost.textMarkdown && <ReactMarkdown rehypePlugins={[rehypeRaw, [rehypePrism, { ignoreMissing: true }]]} components={{pre: PreComponent, img: ImageComponent}}>{blogPost.textMarkdown}</ReactMarkdown>}
        
        {props.pageContext.series && <BlogSeries posts={props.data.BlogSeries} seriesName={props.pageContext.series} currentPosts={blogPost.title} />}

        {blogPost.authors && blogPost.authors.map(author => {
          return (
            <div className="PostBottomAuthorGrid" key={author.slug.current}>
              <Person person={author}>
                <Button className="PostBottomAuthorButton corporate-light transparent" to={`/blog/authors/${author.slug.current}/`} arrow>View all posts</Button>
              </Person>
            </div>
          )
        })}

        <div className="PostBottomBar">
          {blogPost.tags &&
            <div className="PostBottomBarTags">
              {blogPost.tags.map((tag, index) => (
                <div className="PostBottomBarTag" key={index}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#000000">
                    <path d="M0 0h24v24H0V0z" fill="none"/><path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z"/><circle cx="6.5" cy="6.5" r="1.5"/>
                  </svg>
                  <Link to={`/blog/tags/${tag.slug.current}`} key={index}>
                    {tag.name}
                  </Link>
                </div>
              ))}
            </div>
          }
          <div style={{ flex: '1 auto' }} />
          <Share twitter linkedin href={location.href} />
        </div>
      </article>
      {finalRelatedPosts.length > 0 &&
        <div className="RelatedPostsListing">
          <BlogPostsListing theme="Light" posts={finalRelatedPosts.slice(0, 2)} title="Related content" />
        </div>
      }
    </PageLayout>
  );
}

export default SanityBlog;